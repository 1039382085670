
export const studyPlanColumns = [
  {
    title: '序号',
    dataIndex: 'number',
    scopedSlots: { customRender: 'number' },
  },
  {
    title: '阶段名称',
    dataIndex: 'stageTitle',
    width: '30%'
  },
  {
    title: '状态',
    dataIndex: 'putawayFlag',
    scopedSlots: { customRender: 'putawayFlag' },
    width: '30%'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  }
];
// 学习规划-标签
export const studyPlanTagsColumns = [
  {
    title: '序号',
    dataIndex: 'number',
    scopedSlots: { customRender: 'number' },
  },
  {
    title: '标签名称',
    dataIndex: 'tagTitle',
    with: '40%'
  },
  {
    title: '状态',
    dataIndex: 'putawayFlag',
    scopedSlots: { customRender: 'putawayFlag' },
    with: '30%'
  },
  {
    title: '标签类型',
    dataIndex: 'tagType',
    scopedSlots: { customRender: 'tagType' },
    with: '30%'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  }
];

// 学习规划-内容管理-章节管理
export const StudyContentLessonColumns = [
  {
    title: "序号",
    dataIndex: "number",
    key: "number",
    scopedSlots: { customRender: "number" },
  },
  {
    title: "课节名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "操作",
    key: "operation",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
]
// 学习规划-内容管理-添加课节
export const StudyContentSelectLessonColumns = [
  {
    title: "序号",
    dataIndex: "number",
    key: "number",
    scopedSlots: { customRender: "number" },
    width: '15%'
  },
  {
    title: "课节名称",
    dataIndex: "name",
    key: "name",
    width: '50%'
  },
  {
    title: "课节类型",
    dataIndex: "lessonsText",
    key: "lessonsText",
  },
]

// 排序字段验证
export const validateSort = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入排序'));
  } else if (isNaN(+value)) {
    callback(new Error('请输入合法数字'));
  } else {
    callback()
  }
}
// 验证课节内容字段
export const validateLessonsNo = (rule, value, callback) => {
  console.log(value === '' || value === undefined, value)
  if (value === '' || value === undefined) {
    callback(new Error('请选择课节内容'));
  } else {
    callback()
  }
}
